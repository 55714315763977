import { parse } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export function useEventDate() {
  // Format: dd.mm.yyyy
  const ddmmyyyy = /^\d\d\.\d\d\.\d\d\d\d$/;
  // Format dd./dd.mm.yyyy with random delimiter between days and allowed spaces around delimiter/
  const ddddmmyyyy = /^(\d\d).?\s?(.?)\s?(\d\d)\.(\d\d)\.(\d\d\d\d)$/;

  const getDate = (d) => {
    if (d.match(ddmmyyyy)) {
      return parse(d, 'dd.MM.yyyy', new Date());
    }

    const match = d.match(ddddmmyyyy);
    if (match !== null) {
      return parse(`${match[1]}.${match[4]}.${match[5]}`, 'dd.MM.yyyy', new Date());
    }

    return null;
  };

  const getDayOfMonth = (d) => {
    if (d.match(ddmmyyyy) !== null) {
      const date = parse(d, 'dd.MM.yyyy', new Date());

      return date.getDate();
    }

    // Check if format is dd./dd.mm.yyyy
    const match = d.match(ddddmmyyyy);
    if (match !== null) {
      let day1 = parse(`${match[1]}.${match[4]}.${match[5]}`, 'dd.MM.yyyy', new Date()).getDate();
      let day2 = parse(`${match[3]}.${match[4]}.${match[5]}`, 'dd.MM.yyyy', new Date()).getDate();

      if (day1 < 10) {
        day1 = `0${day1}`;
      }
      if (day2 < 10) {
        day2 = `0${day2}`;
      }

      return `${day1}${match[2]}${day2}`;
    }

    return '';
  };

  return {
    ddmmyyyy,
    ddddmmyyyy,
    getDate,
    getDayOfMonth,
  };
}
