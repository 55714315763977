<template>
  <section class="events-preview pb-12 font-sans">
    <div class="constrain-2/3">
      <div class="text-center">
        <h1 class="h2 mb-8" v-text="payload.title" />
      </div>
      <div
        v-for="(events, month) in eventsByMonth"
        :key="month"
      >
        <h4 class="mb-4" v-text="month" />
        <Go
          v-for="event in events"
          :key="event.id"
          :to="event.link"
          class="event-link"
        >
          <div class="font-bold text-lg mr-4 w-14" v-text="getDayOfMonth(event.date)" />
          <div class="flex flex-col">
            <small class="text-sm font-serif block" v-text="event.tag" />
            <div class="font-bold" v-text="event.title" />
          </div>
        </Go>
      </div>
      <div v-if="!payload.hidePrevNext" class="flex items-center justify-between">
        <jk-button to="/de/events" class="hover-primary hidden sm:block">
          <span class="flex items-center">
            <Arrow class="w-3 transform rotate-90 mr-4" />
            ältere
          </span>
        </jk-button>
        <jk-button to="/de/events" class="hover-primary hidden sm:block">
          <span class="flex items-center">
            <Arrow class="w-3 transform -rotate-90 mr-4" />
            spätere
          </span>
        </jk-button>
        <jk-button to="/de/events" class="hover-primary sm:hidden mx-auto">
          <span class="flex items-center">
            <Arrow class="w-3 transform -rotate-90 mr-4" />
            weitere events
          </span>
        </jk-button>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import { parse } from 'date-fns';
import JkButton from '@/components/utils/jkButton.vue';
import Arrow from '@/assets/images/arrow.svg';
import { useEventDate } from '@/composables/date';

export default {
  components: { Arrow, JkButton },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const { getDate, getDayOfMonth } = useEventDate();
    const eventsByMonth = ref({});

    props.payload.events.forEach((e) => {
      const date = getDate(e.date);
      if (date) {
        const month = date.toLocaleString('de', { month: 'long' });

        if (!eventsByMonth.value[month]) {
          eventsByMonth.value[month] = [];
        }
        eventsByMonth.value[month].push(e);
      }
    });

    return {
      getDate,
      getDayOfMonth,
      eventsByMonth,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-link {
  @apply no-underline;
  @apply flex items-center;
  @apply border-b-2 border-black;
  @apply mb-4;

  &:last-child {
    @apply mb-8;
  }
}
</style>
